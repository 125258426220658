import { Box } from "@mui/material";
import CustomTabPanel from "../../components/layout/customTabPanel/customTabPanel";
import AutomobileList from "./components/automobileList";

export default function AutomobilePage() {
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider", pt: 2 }}>
        <AutomobileList />
      </Box>
      <CustomTabPanel value={0} index={0}></CustomTabPanel>
    </Box>
  );
}
