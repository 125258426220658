import React from "react";
import useQueryNotification from "../../api/queries/useQueryNotification";
import SectionLayout from "../../components/layout/sectionLayout";
import {
  Backdrop,
  Box,
  CircularProgress,
  Divider,
  Typography,
} from "@mui/material";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { formatDate } from "../../utils/formatDate";

const NotificationPage = () => {
  const { data, isLoading } = useQueryNotification();
  const transactions = data?.data || [];

  return (
    <div>
      <Backdrop
        sx={{
          color: "#0D109F",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <SectionLayout title="Notifications">
        <Box sx={{ paddingX: 5 }}>
          {transactions.map((item: any) => (
            <>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  paddingY: 2,
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    padding: 2,
                  }}
                >
                  <NotificationsNoneIcon color="primary" />
                  <Typography
                    sx={{
                      paddingLeft: 2,
                      fontWeight: "400",
                      fontSize: "16px",
                      lineHeight: "23px",
                    }}
                  >{`[${item.name}]:${item.message}`}</Typography>
                </Box>
                <Typography>{formatDate(item.created_at)}</Typography>
              </Box>
              <Divider />
            </>
          ))}
        </Box>
      </SectionLayout>
    </div>
  );
};

export default NotificationPage;
