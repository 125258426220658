export const couleursCarrosserie = [
  "Argent",
  "Beige",
  "Blanc",
  "Bleu",
  "Bleu clair",
  "Bleu foncé",
  "Bronze",
  "Gris",
  "Gris clair",
  "Gris foncé",
  "Jaune",
  "Marron",
  "Noir",
  "Or",
  "Rouge",
  "Rouge clair",
  "Rouge foncé",
  "Vert",
  "Vert clair",
  "Vert foncé",
];

export const typesMoteurs = [
  "Diesel",
  "Essence Hybride",
  "Diesel Hybride",
  "Essence à injection directe",
  "Essence à injection indirecte",
  "Allumage commandé (essence)",
  "Compression variable",
  "Électrique à batterie",
  "Électrique à pile à combustible (hydrogène)",
  "Électrique hybride (essence/électrique, diesel/électrique)",
  "Alternatif à air comprimé",
  "Stirling",
  "Vapeur",
  "Rotatif Wankel",
  "Turbine à réaction (turbofan, turbopropulseur)",
  "Turbine à vapeur",
  "À eau salée",
  "À énergie solaire",
];

export const brands = [
  { label: "Abarth", id: 1 },
  { label: "Alfa Romeo", id: 29 },
  { label: "Audi", id: 30 },
  { label: "BMW", id: 31 },
  { label: "Bugatti", id: 25 },
  { label: "Buick", id: 18 },
  { label: "Cadillac", id: 23 },
  { label: "Chevrolet", id: 22 },
  { label: "Citroën", id: 21 },
  { label: "Dacia", id: 32 },
  { label: "Dodge", id: 7 },
  { label: "Fiat", id: 26 },
  { label: "Ford", id: 33 },
  { label: "Honda", id: 17 },
  { label: "Hummer", id: 15 },
  { label: "Hyundai", id: 16 },
  { label: "Infiniti", id: 24 },
  { label: "Isuzu", id: 14 },
  { label: "Kia", id: 20 },
  { label: "Lamborghini", id: 9 },
  { label: "Lotus", id: 8 },
  { label: "Maserati", id: 10 },
  { label: "Mercedes", id: 6 },
  { label: "Mitsubishi", id: 11 },
  { label: "Nissan", id: 13 },
  { label: "Opel", id: 34 },
  { label: "Peugeot", id: 19 },
  { label: "Renault", id: 35 },
  { label: "RollsRoyce", id: 3 },
  { label: "Rover", id: 5 },
  { label: "Subaru", id: 28 },
  { label: "Suzuki", id: 4 },
  { label: "Toyota", id: 36 },
  { label: "Volkswagen", id: 27 },
];

export const carReasons = [
  { label: "En Location", id: 1 },
  { label: "En Vente", id: 2 },
  //{ label: "Vendu", id: 3 },
];

export const getCarReasonLabelById = (id: number) => {
  const reason = carReasons.find((reason) => reason.id == id);
  return reason ? reason.label : null;
};

export const getCarReasonIdByLabel = (label: string | undefined): Number => {
  const reason = carReasons.find((reason) => reason.label == label);
  return reason ? reason.id : 2;
};

export const appName = "House & Car";
export const app = {
  name: appName,
  logo: "/assets/logo_hc.jpg",
};
