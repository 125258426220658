import { Box } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import fetchApi from "../api/fetchApi";
import Dialog from "../components/dialog/Dialog";
import CustomInput from "../components/inputs/cutsomInput";
import SelectFree from "../components/inputs/selectFree";
import {
  carReasons,
  couleursCarrosserie,
  getCarReasonLabelById,
  typesMoteurs,
} from "../constants/common";
import useAuth from "../hooks/useAuth";

type FormCreateEditType =
  | {
      context: "edit" | "delete";
      car: any;
      show: boolean;
      setShow: any;
    }
  | {
      context: "create";
      car: undefined;
      show: boolean;
      setShow: any;
    };

const FormCreateEditCar = ({
  context,
  car,
  show,
  setShow,
}: FormCreateEditType) => {
  const { handleSubmit, control, reset, register } = useForm();
  const [isSaving, setisSaving] = useState(false);
  const { token } = useAuth();
  const [image, setImage] = useState<any>();
  const queryClient = useQueryClient();
  const isEditing = context === "edit" || context === "delete";

  const updateCar = async (data: any) => {
    setisSaving(true);
    const formData = new FormData();
    const reason = data?.reason?.id || 2;

    if (isEditing) {
      formData.append("id", Number(car?.id).toString());
    }

    if (context === "delete") {
      formData.append("nom", car?.nom);
      formData.append("prix", Number(car?.prix).toString());
      formData.append("active", car?.active === "1" ? "0 " : "1");
    } else {
      formData.append("nom", data?.nom);
      formData.append("prix", Number(data?.prix).toString());
      formData.append("annee", Number(data?.annee).toString());
      formData.append("chevaux", data?.chevaux);
      formData.append("interiorcolor", data?.interiorcolor);
      formData.append("portiere", Number(data?.portiere).toString());
      formData.append("carburant", data?.carburant);
      formData.append("kilometrage", Number(data?.kilometrage).toString());
      formData.append("carrosserie", data?.carrosserie);
      formData.append("description", data?.description);
      formData.append("transmission", data?.transmission);
      formData.append("reason", String(reason));
      formData.append("moteur", data?.moteur);
      formData.append("marque", data?.marque);
      if (image) {
        formData.append("image", image);
      }
    }

    fetchApi(`car${isEditing ? "/update" : ""}`, token)
      .upload(formData)
      .then((e) => {
        if (e.error) {
          toast(e.messages.message);
        } else if (e.title === "TypeError") {
          toast(e.message);
        } else {
          queryClient.invalidateQueries({
            queryKey: ["CarDetails"],
          });
          queryClient.invalidateQueries({
            queryKey: ["Automobile"],
          });
          handleCloseEdit();
          !isEditing && reset();
          toast("Les  détails du véhicule ont été modifié", {
            type: "success",
          });
        }
      })
      .catch((err) => toast(err.message, { type: "error" }))
      .finally(() => setisSaving(false));
  };

  const uploadimg = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      console.log(file);
      setImage(e.target.files[0]);
    }
  };

  const handleCloseEdit = () => {
    setShow(false);
  };

  return (
    <Dialog
      title={
        isEditing
          ? context === "delete"
            ? car?.active === "0"
              ? `Activer ${car?.nom}`
              : `Supprimer ${car?.nom}`
            : `Modifier ${car?.nom}`
          : "Créer un nouveau véhicule"
      }
      handleClose={handleCloseEdit}
      open={show}
      confirm={handleSubmit(updateCar)}
      isStoreActivated={false}
      isConfirming={isSaving}
      id={1}
    >
      <>
        {context === "delete" ? (
          <></>
        ) : (
          <>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <CustomInput
                control={control}
                id="nom"
                label="Nom"
                name="nom"
                defaultValue={car?.nom || ""}
                disabled={isSaving}
                rules={{
                  required: "This field is required",
                }}
                autoFocus
              />
              <br />
              <CustomInput
                control={control}
                id="prix"
                label="Prix du véhicule"
                name="prix"
                disabled={isSaving}
                defaultValue={car?.prix || ""}
                rules={{
                  required: "This field is required",
                }}
              />
            </Box>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <CustomInput
                control={control}
                id="portiere"
                label="Nombre de portières"
                name="portiere"
                defaultValue={car?.portiere || ""}
                disabled={isSaving}
              />
              <br />
              <CustomInput
                control={control}
                id="kilometrage"
                label="Kilometrage (km)"
                name="kilometrage"
                disabled={isSaving}
                defaultValue={car?.kilometrage || ""}
              />
            </Box>

            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <CustomInput
                control={control}
                id="annee"
                label="Année"
                name="annee"
                defaultValue={car?.annee || ""}
                disabled={isSaving}
              />
              <br />
              <CustomInput
                control={control}
                id="chevaux"
                label="Nbre Chevaux"
                name="chevaux"
                disabled={isSaving}
                defaultValue={car?.chevaux || ""}
              />
            </Box>

            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ width: "100%" }}>
                <SelectFree
                  fullWidth
                  control={control}
                  rules={{
                    required: isEditing ? undefined : "This field is required",
                  }}
                  id="interiorcolor"
                  label="Location/Vente"
                  name="reason"
                  defaultValue={getCarReasonLabelById(car?.reason)}
                  disabled={isSaving}
                  options={carReasons}
                />
              </Box>
              <Box sx={{ width: "100%" }}>
                <SelectFree
                  fullWidth
                  control={control}
                  rules={{
                    required: isEditing ? undefined : "This field is required",
                  }}
                  id="interiorcolor"
                  label="Couleur intérieure"
                  name="interiorcolor"
                  defaultValue={car?.interiorcolor || ""}
                  disabled={isSaving}
                  options={couleursCarrosserie}
                />
              </Box>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ width: "100%" }}>
                <SelectFree
                  fullWidth
                  control={control}
                  rules={{
                    required: isEditing ? undefined : "This field is required",
                  }}
                  id="carrosserie"
                  label="Carrosserie"
                  name="carrosserie"
                  defaultValue={car?.carrosserie || ""}
                  disabled={isSaving}
                  options={couleursCarrosserie}
                />
              </Box>

              <br />
              <Box sx={{ width: "100%" }}>
                <SelectFree
                  id="transmission"
                  fullWidth
                  control={control}
                  rules={{
                    required: "This field is required",
                  }}
                  label="Transmission"
                  name="transmission"
                  defaultValue={car?.transmission || ""}
                  options={["Automatique", "Manuelle"]}
                />
              </Box>
            </Box>

            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              <Box sx={{ width: "100%" }}>
                <SelectFree
                  fullWidth
                  control={control}
                  rules={{
                    required: "This field is required",
                  }}
                  id="carburant"
                  label="Carburant"
                  name="carburant"
                  disabled={isSaving}
                  defaultValue={car?.carburant || ""}
                  options={["Essence", "Gazoil"]}
                />
              </Box>

              <br />
              <Box sx={{ width: "100%" }}>
                <SelectFree
                  id="moteur"
                  fullWidth
                  control={control}
                  rules={{
                    required: "This field is required",
                  }}
                  label="Moteur"
                  name="moteur"
                  defaultValue={car?.moteur || ""}
                  options={typesMoteurs}
                />
              </Box>
            </Box>

            <CustomInput
              control={control}
              id="description"
              label="Description du véhicule"
              name="description"
              disabled={isSaving}
              defaultValue={car?.description || ""}
            />
            <input type="file" {...register("file")} onChange={uploadimg} />
          </>
        )}
      </>
    </Dialog>
  );
};

export default FormCreateEditCar;
