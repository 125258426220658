import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { Avatar, Box, IconButton, Tooltip, Typography } from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import useAuth from "../../../hooks/useAuth";
import Search from "../../elements/search/search";
import { drawerWidth } from "../sideNav/sideNav";
import { useNavigate } from "react-router-dom";
import { app } from "../../../constants/common";

interface HeaderProps {
  handleDrawerOpenClose: () => void;
  isHomePage?: boolean;
}

const Header = ({ handleDrawerOpenClose, isHomePage = false }: HeaderProps) => {
  const navigate = useNavigate();
  const { user } = useAuth();

  return (
    <AppBar
      position="static"
      color="secondary"
      sx={{ height: drawerWidth - 180, flex: 1, justifyContent: "center" }}
    >
      <Toolbar
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Box>
          <Search />
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <IconButton sx={{ mx: 3 }} onClick={() => navigate("notifications")}>
            <NotificationsNoneIcon color="primary" />
          </IconButton>
          <Tooltip title="Open settings" onClick={() => navigate("settings")}>
            <IconButton sx={{ p: 0 }}>
              <Avatar alt={app.name} src={app.logo} />
            </IconButton>
          </Tooltip>
          <Typography sx={{ px: 3 }}>Welcome, {user?.nom}</Typography>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
