import { Box, Grid, Paper, Typography } from "@mui/material";
import React, { ReactNode } from "react";

const Card = ({
  title,
  children,
  bgColor = "#F4E5E5",
}: {
  title: string;
  children: ReactNode;
  bgColor?: string;
}) => {
  return (
    <Box sx={{ height: "auto", padding: 5 }}>
      <Paper sx={{}}>
        <Box
          sx={{
            padding: "15px 0 15px 15px",
            backgroundColor: bgColor,
            alignContent: "center",
          }}
        >
          <Typography mb="5px" fontWeight={600}>
            {title}
          </Typography>
        </Box>

        <Grid style={{ backgroundColor: "white" }}>{children}</Grid>
      </Paper>
    </Box>
  );
};

export default Card;
