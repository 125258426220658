import { useContext } from "react";
import { AuthContext } from "../context/authContext";

function useAuth() {
  const { user, login, logout, updateUser, token, isLoading } =
    useContext(AuthContext);

  return {
    authenticated: !!user,
    user,
    login,
    logout,
    updateUser,
    token,
    isLoading,
  };
}

export default useAuth;
