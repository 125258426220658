export default function getErrorMessages(response: any): string[] {
  // Vérifier si la réponse contient un objet "messages"
  if (response.messages) {
    // Récupérer toutes les valeurs des clés de l'objet "messages"
    const errorMessages: string[] = Object.values(response.messages);
    return errorMessages;
  } else {
    return [];
  }
}
