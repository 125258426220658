import useAuth from "../hooks/useAuth";
import AppRouter from "./appRouter";
import AuthRouter from "./authRouter";

function Routes() {
  //const { user, isLoading } = useContext(AuthContext);

  const { user } = useAuth();

  return <>{user ? <AppRouter /> : <AuthRouter />}</>;
}

export default Routes;
