import React from "react";
import {
  Controller,
  FieldValues,
  FieldPath,
  UseControllerProps,
} from "react-hook-form";
import { SxProps, TextField, TextFieldVariants } from "@mui/material";

interface CustomInputProps<TFieldValues extends FieldValues = FieldValues> {
  control: UseControllerProps<TFieldValues>["control"];
  name: FieldPath<TFieldValues>;
  rules?: UseControllerProps<TFieldValues>["rules"];
  placeholder?: string;
  secureTextEntry?: boolean;
  label?: string;
  defaultValue?: any;
  disabled?: boolean;
  sx?: SxProps;
  variant?: TextFieldVariants;
  required?: boolean;
  id?: string;
  autoFocus?: boolean;
  type?: string;
}

const CustomInput = <TFieldValues extends FieldValues>({
  control,
  name,
  id = "",
  rules = {},
  type = "text",
  placeholder,
  disabled = false,
  autoFocus = false,
  defaultValue,
  variant = "outlined",
  required = false,
  label = "",
  sx = { width: "100%", paddingBottom: 5 },
  ...rest
}: CustomInputProps<TFieldValues>) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={defaultValue}
      disabled={disabled}
      {...rest}
      render={({ field: { onChange, value }, fieldState: { error } }) => (
        <TextField
          helperText={error ? error.message : null}
          error={!!error}
          data-testid={"account_number"}
          id={id}
          onChange={onChange}
          value={value}
          size="medium"
          sx={sx}
          variant={variant}
          label={label}
          disabled={disabled}
          fullWidth
          autoFocus={autoFocus}
          type={type}
        />
      )}
    />
  );
};

export default CustomInput;
