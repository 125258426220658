import {
  Box,
  colors,
  Dialog as Dial,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import Button from "../button/customButton";
import { ReactNode } from "react";

interface ConfirmationDialogProps {
  handleClose: () => void;
  title: string;
  children: ReactNode;
  open: boolean;
  confirm: () => void;
  id: number;
  isStoreActivated: boolean;
  isConfirming?: boolean;
}

const Dialog = ({
  handleClose,
  open,
  confirm,
  id,
  isStoreActivated,
  title,
  children,
  isConfirming = false,
}: ConfirmationDialogProps) => {
  return (
    <Dial open={open} onClose={handleClose}>
      <Box sx={{ backgroundColor: "red" }}>
        <DialogTitle id="alert-dialog-title" sx={{ color: "white" }}>
          {title}
        </DialogTitle>
      </Box>

      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {children}
        </DialogContentText>
      </DialogContent>
      <DialogActions sx={{ backgroundColor: "#FAFAFA" }}>
        <Button
          onClick={handleClose}
          variant="outlined"
          color={"error"}
          isLoading={isConfirming}
          disabled={isConfirming}
        >
          annuler
        </Button>
        <Button
          onClick={confirm}
          isLoading={isConfirming}
          autoFocus
          color={isStoreActivated ? "error" : "primary"}
        >
          confirmer
        </Button>
      </DialogActions>
    </Dial>
  );
};

export default Dialog;
