import React, { ReactNode } from "react";

import { Box, Typography } from "@mui/material";

interface SectionLayoutProps {
  title: string;
  children: ReactNode;
  rightContent?: ReactNode;
}

const SectionLayout = ({
  children,
  title,
  rightContent,
}: SectionLayoutProps) => {
  return (
    <>
      <Box
        sx={{
          width: "100%",
          padding: "16px",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Typography
            sx={{
              fontSize: 18,

              fontWeight: "500",
              letterSpacing: 1,
              lineHeight: "23.5px",
            }}
          >
            {title}
          </Typography>
          {rightContent && rightContent}
        </Box>
      </Box>
      <Box
        sx={{
          paddingHorizontal: 16,
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "space-around",
        }}
      >
        {children}
      </Box>
    </>
  );
};

export default SectionLayout;
