import React from "react";
import "./App.css";
import { ThemeProvider } from "@mui/material";
import theme from "./config/theme";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Navigation from "./navigation";

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Navigation />
      <ToastContainer />
    </ThemeProvider>
  );
}

export default App;
