import { Autocomplete, TextField } from "@mui/material";
import { Controller } from "react-hook-form";

export default function SelectFree({
  custom = false,
  name,
  rules,
  options,
  getOptionLabel,
  sx = { width: "100%", paddingBottom: 5 },
  ...rest
}: any) {
  return (
    <Controller
      name={name}
      control={rest.control}
      rules={rules}
      defaultValue={rest.defaultValue}
      render={({
        field: { ref, ...field },
        fieldState: { error, invalid },
      }) => {
        return (
          <Autocomplete
            {...field}
            freeSolo
            handleHomeEndKeys
            options={options}
            getOptionLabel={getOptionLabel}
            defaultValue={rest.defaultValue}
            renderInput={(params) => (
              <TextField
                {...params}
                {...rest}
                inputRef={ref}
                sx={sx}
                error={invalid}
                helperText={error?.message}
              />
            )}
            onChange={(e, value) => field.onChange(custom ? value?.id : value)}
            onInputChange={(_, data) => {
              if (data) field.onChange(data);
            }}
          />
        );
      }}
    />
  );
}
