import {
  Box,
  Container,
  CssBaseline,
  Divider,
  Typography,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import fetchApi from "../../api/fetchApi";
import Button from "../../components/button/customButton";
import Footer from "../../components/footer/footer";
import CustomInput from "../../components/inputs/cutsomInput";
import useAuth from "../../hooks/useAuth";
import { app } from "../../constants/common";
import { toast } from "react-toastify";
import getErrorMessages from "../../utils/getErrorMessages";

const ChangePassword = ({ close }: { close: any }) => {
  const { handleSubmit, control } = useForm();
  const [isLogin, setIsLogin] = useState(false);
  const [errorMessage, setErrorMessage] = useState([]);
  const [step, setStep] = useState(1);
  const [seconds, setSeconds] = useState(60);
  const [resend, setResend] = useState(false);
  const { token } = useAuth();

  const resetPassword = async (data: any) => {
    setIsLogin(true);
    setErrorMessage([]);

    fetchApi("update/password", token)
      .post({
        current_password: data.current_password,
        password: data.old_password,
        confirm_password: data.confirm_password,
      })
      .then((t) => {
        if (t.message === "Password updated") {
          toast("Mot de passe mis à jour");
          close();
        } else {
          const messages = getErrorMessages(t);
          setErrorMessage(messages as any);
        }
      })
      .catch((err) => {
        console.log(err);
        setErrorMessage(["An error Occured. kindly try later!"] as any);
      })
      .finally(() => setIsLogin(false));
  };

  useEffect(() => {
    if (step === 2) {
      const timer = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        } else {
          setResend(true);
          clearInterval(timer);
        }
      }, 1000);
      return () => clearInterval(timer);
    }
    // Nettoyer le timer à la fin du composant
  }, [seconds, step]);

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{ flex: 1, justifyContent: "center", alignItems: "center" }}
    >
      <CssBaseline />
      <Box
        sx={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img src={app.logo} alt="Logo" style={{ height: 100 }} />
        <Box sx={{ width: "100%" }}>
          <Typography
            component="h3"
            variant="h5"
            textAlign={"left"}
            pt={5}
            color={"red"}
          >
            {errorMessage.toString()}
          </Typography>
          <Typography component="h1" variant="h5" textAlign={"left"} pt={5}>
            Changez votre mot de passe
          </Typography>
        </Box>

        <Box sx={{ mt: 1 }}>
          <>
            <CustomInput
              control={control}
              name="current_password"
              label="Mot de passe actuel"
              type="password"
              id="current_password"
              disabled={isLogin}
              rules={{
                required: "Ce champ est requis",
              }}
            />

            <CustomInput
              control={control}
              name="old_password"
              label="Nouveau Mot de passe"
              type="password"
              id="old_password"
              disabled={isLogin}
              rules={{
                required: "Ce champ est requis",
              }}
            />

            <CustomInput
              control={control}
              name="confirm_password"
              label="Confirmez Mot de passe"
              type="password"
              id="confirm_password"
              disabled={isLogin}
              rules={{
                required: "Ce champ est requis",
              }}
            />

            <Button
              type="submit"
              fullWidth
              variant="contained"
              size={"large"}
              sx={{ mt: 3, mb: 2 }}
              isLoading={isLogin}
              disabled={isLogin}
              onClick={handleSubmit(resetPassword)}
            >
              Confirmer
            </Button>
          </>
        </Box>
      </Box>
      <Box sx={{ position: "absolute", bottom: 25, left: 0, right: 0 }}>
        <Divider />
        <Footer sx={{ mt: 8, mb: 4 }} />
      </Box>
    </Container>
  );
};

export default ChangePassword;
