import { Box } from "@mui/material";
import CustomTabPanel from "../../components/layout/customTabPanel/customTabPanel";
import DealsList from "./components/dealsList";

const DealsPage = () => {
  return (
    <Box sx={{ width: "100%" }}>
      <Box sx={{ borderBottom: 1, borderColor: "divider", pt: 2 }}>
        <DealsList />
      </Box>
      <CustomTabPanel value={0} index={0}></CustomTabPanel>
    </Box>
  );
};

export default DealsPage;
