import React from "react";
import Paper from "@mui/material/Paper";
import TableContainer from "@mui/material/TableContainer";
import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import styled from "@emotion/styled";
import Button from "../../button/customButton";
import { useNavigate } from "react-router-dom";

interface CustomTableProps {
  columns: {
    label: string;
    property: string;
    formatter?: (value: any) => any;
  }[];
  rows: Record<string, any>[];
  customAction?: (rowValue: any) => void;
  customTitleButton?: string;
  showButton?: Boolean;
  editButton?: Boolean;
}

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F4E5E5",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {},
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const Datagrid: React.FC<CustomTableProps> = ({
  columns,
  rows,
  customAction,
  showButton,
  editButton,
  customTitleButton,
}) => {
  const navigate = useNavigate();

  const handleCustomAction = (rowValue: any) => {
    if (customAction) {
      customAction(rowValue);
    }
  };

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            {columns.map((column, index) => (
              <StyledTableCell
                key={index}
                align="left"
                sx={{ display: column?.property === "id" ? "none" : undefined }}
              >
                {column.label}
              </StyledTableCell>
            ))}
            {(showButton || editButton || customAction) && ( // Vérifiez si la propriété actions est définie
              <StyledTableCell align="left">Actions</StyledTableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, rowIndex) => (
            <StyledTableRow key={rowIndex}>
              {columns.map((column, columnIndex) => (
                <StyledTableCell
                  key={columnIndex}
                  align="left"
                  sx={{
                    display: column?.property === "id" ? "none" : undefined,
                  }}
                >
                  {column.formatter
                    ? column.formatter(row[column.property])
                    : row[column.property]}
                </StyledTableCell>
              ))}
              {(showButton || editButton || customAction) && ( // Affichez les actions si la propriété actions est définie
                <StyledTableCell align="left">
                  {showButton && (
                    <Button
                      color="primary"
                      onClick={() => navigate(`${row?.id}`)}
                    >
                      Voir Details
                    </Button>
                  )}
                  {customAction && (
                    <Button
                      color="primary"
                      onClick={() => handleCustomAction(row)}
                    >
                      {customTitleButton}
                    </Button>
                  )}
                </StyledTableCell>
              )}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default Datagrid;
