import { Route, Routes } from "react-router-dom";
import LoginPage from "../pages/auth/loginPage";

function AuthRouter() {
  return (
    <div className="flex w-full h-full bg-white  overflow-scroll  ">
      <Routes>
        <Route path="*" element={<LoginPage />} />
      </Routes>
    </div>
  );
}

export default AuthRouter;
