import { Box } from "@mui/material";
import { useState } from "react";
import { host } from "../../../api/apihost";
import { useQueryImmobilier } from "../../../api/queries";
import Button from "../../../components/button/customButton";
import Datagrid from "../../../components/layout/customTable/Datagrid";
import SectionLayout from "../../../components/layout/sectionLayout";
import { FormCreateEditImmo } from "../../../shared";
import formatCurrency from "../../../utils/formatCurrency";
import { formatDate } from "../../../utils/formatDate";
import { newSelectObjectByProperties } from "../../../utils/selectObjectByProperties";

const ImmobilierList = () => {
  const { data } = useQueryImmobilier();
  const [showCreate, setShowCreate] = useState(false);
  const columns = [
    { label: "id", property: "id" },
    {
      label: "Image",
      property: "image",
      formatter: (text: string) => (
        <Box
          component="img"
          sx={{
            height: 100,
            width: 100,
            maxHeight: { xs: 100, md: 100 },
            maxWidth: { xs: 100, md: 100 },
          }}
          alt=""
          src={
            text.startsWith("uploads")
              ? `https://houseandcar.shop/apiv2/public/${text}`
              : `${host}${text}`
          }
        />
      ),
    },
    { label: "Nom", property: "nom" },
    { label: "Prix", property: "prix", formatter: formatCurrency },
    { label: "Responsable", property: "prix", formatter: () => "" },

    {
      label: "Date d'enregistrement",
      property: "registered",
      formatter: formatDate,
    }, // Assurez-vous que la propriété "action" existe dans vos objets row
  ];
  const list = data?.data || [];
  const selectedRows = newSelectObjectByProperties(list, columns);

  return (
    <div>
      <SectionLayout
        title="Tous les biens Immo"
        rightContent={
          <Button onClick={() => setShowCreate(true)}>
            Ajouter un bien immo
          </Button>
        }
      >
        <FormCreateEditImmo
          immo={undefined}
          show={showCreate}
          setShow={setShowCreate}
          context="create"
        />
        <Datagrid columns={columns} rows={selectedRows} showButton editButton />
      </SectionLayout>
    </div>
  );
};

export default ImmobilierList;
