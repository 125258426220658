import Moment from "moment";

export const formatDate = (dt: string) => {
  if (!dt) {
    return "";
  }
  Moment.locale("fr");
  const date = new Date(Date.parse(dt));

  const a = Moment();
  const b = Moment(date);
  const c = a.diff(b, "days");
  if (c > 365) {
    return Moment(dt).format(" DD MMM. YYYY HH:mm");
  } else if (c >= 2) {
    return Moment(date).format(" DD MMM.  HH:mm");
  } else {
    if (Moment(date).format(" DD MM.") === Moment().format(" DD MM.")) {
      return Moment(date).format(" HH:mm");
    } else {
      return Moment(date).format(" DD MMM.  HH:mm");
    }
  }
};
