import { Box } from "@mui/material";
import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import fetchApi from "../api/fetchApi";
import Dialog from "../components/dialog/Dialog";
import CustomInput from "../components/inputs/cutsomInput";
import SelectFree from "../components/inputs/selectFree";
import useAuth from "../hooks/useAuth";
import { useQueryVille } from "../api/queries";

type FormCreateEditType =
  | {
      context: "edit";
      immo: any;
      show: boolean;
      setShow: any;
    }
  | {
      context: "create";
      immo: undefined;
      show: boolean;
      setShow: any;
    };

const FormCreateEditImmo = ({
  context,
  immo,
  show,
  setShow,
}: FormCreateEditType) => {
  const { handleSubmit, control, reset, register } = useForm();
  const [isSaving, setisSaving] = useState(false);
  const { token } = useAuth();
  const [image, setImage] = useState<any>();
  const queryClient = useQueryClient();
  const isEditing = context === "edit";
  const { data: villes } = useQueryVille();

  const transformData = (data: any) => {
    return data.map((item: any) => ({
      id: item.id,
      label: item.nom,
    }));
  };

  const propertyArray = [
    { title: "concierge", value: immo?.concierge === "1" ? "Oui" : "Non" },
    { title: "parking", value: immo?.parking === "1" ? "Oui" : "Non" },
    { title: "ascenseur", value: immo?.ascenseur === "1" ? "Oui" : "Non" },
    { title: "piscine", value: immo?.piscine === "1" ? "Oui" : "Non" },
    { title: "balcon", value: immo?.balcon === "1 " ? "Oui" : "Non" },
    { title: "jardin", value: immo?.jardin === "1" ? "Oui" : "Non" },
  ];

  const updateImmo = async (data: any) => {
    setisSaving(true);
    const formData = new FormData();

    if (isEditing) {
      formData.append("id", Number(immo?.id).toString());
    }
    formData.append("nom", data?.nom);
    formData.append("prix", Number(data?.prix).toString());
    formData.append("pour", data?.pour);
    formData.append("quoi", data?.quoi);
    formData.append("surface", data?.surface);
    formData.append("nbpiece", Number(data?.nbpiece).toString());
    formData.append("nbchambre", Number(data?.nbchambre).toString());
    formData.append("nbsallebain", Number(data?.nbsallebain).toString());
    formData.append("localisation", data?.localisation);
    formData.append("description", data?.description);
    formData.append("ville", data?.ville?.id);

    propertyArray.forEach((element) => {
      formData.append(element.title, data[element.title] === "Oui" ? "1" : "0");
    });

    if (image) {
      formData.append("image", image);
    }

    fetchApi(`immo${isEditing ? "/update" : ""}`, token)
      .upload(formData)
      .then((e) => {
        if (e.error) {
          toast(e.messages.message);
        } else if (e.title === "TypeError") {
          toast(e.message);
        } else {
          queryClient.invalidateQueries({
            queryKey: ["ImmoDetails"],
          });
          queryClient.invalidateQueries({
            queryKey: ["Immobilier"],
          });
          handleCloseEdit();
          !isEditing && reset();
          toast("Les  détails du bien ont été modifié", {
            type: "success",
          });
        }
      })
      .catch((err) => toast(err.message, { type: "error" }))
      .finally(() => setisSaving(false));
  };

  const uploadimg = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      console.log(file);
      setImage(e.target.files[0]);
    }
  };

  const handleCloseEdit = () => {
    setShow(false);
  };

  return (
    <Dialog
      title={isEditing ? `Modifier ${immo?.nom}` : "Créer un nouveau bien immo"}
      handleClose={handleCloseEdit}
      open={show}
      confirm={handleSubmit(updateImmo)}
      isStoreActivated={false}
      isConfirming={isSaving}
      id={1}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <CustomInput
          control={control}
          id="nom"
          label="Nom"
          name="nom"
          defaultValue={immo?.nom || ""}
          disabled={isSaving}
          rules={{
            required: "This field is required",
          }}
          autoFocus
        />
        <br />
        <CustomInput
          control={control}
          id="prix"
          label="Prix du véhicule"
          name="prix"
          disabled={isSaving}
          defaultValue={immo?.prix || ""}
          rules={{
            required: "This field is required",
          }}
        />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ width: "100%" }}>
          <SelectFree
            fullWidth
            control={control}
            rules={{
              required: "This field is required",
            }}
            id="pour"
            label="Pour"
            name="pour"
            disabled={isSaving}
            defaultValue={immo?.pour || ""}
            options={["Vente", "Location"]}
          />
        </Box>
        <Box sx={{ width: "100%" }}>
          <SelectFree
            control={control}
            id="quoi"
            label="Type de bien"
            name="quoi"
            disabled={isSaving}
            defaultValue={immo?.quoi || ""}
            options={[
              "Appartement Non meublé",
              "Appartement meublé",
              "Villa meublé",
              "Villa non meublé",
            ]}
          />
        </Box>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <CustomInput
          control={control}
          id="surface"
          label="Superficie"
          name="surface"
          disabled={isSaving}
          defaultValue={immo?.surface || ""}
          placeholder="ex: 100m2..."
        />
        <Box sx={{ width: "100%" }}>
          <SelectFree
            control={control}
            id="standing"
            label="Standing du bien"
            name="stading"
            disabled={isSaving}
            defaultValue={immo?.stading || ""}
            options={[
              "Vermeille (1 étoile)",
              "Bronze (2 étoiles)",
              "Silver (3 étoiles)",
              "Gold (4 étoiles)",
              "Diamant (5 étoiles)",
            ]}
          />
        </Box>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <CustomInput
          control={control}
          id="nbpiece"
          label="Nombre Salons"
          name="nbpiece"
          defaultValue={immo?.nbpiece || ""}
          disabled={isSaving}
        />
        <CustomInput
          control={control}
          id="nbchambre"
          label="Nombre Chambres"
          name="nbchambre"
          defaultValue={immo?.nbchambre || ""}
          disabled={isSaving}
        />
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <CustomInput
          control={control}
          id="nbsallebain"
          label="Nombre de salle de bain"
          name="nbsallebain"
          defaultValue={immo?.nbsallebain || ""}
          disabled={isSaving}
        />
      </Box>

      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <Box sx={{ width: "100%" }}>
          <SelectFree
            control={control}
            id="ville"
            label="Ville du bien"
            name="ville"
            defaultValue={immo?.ville || ""}
            disabled={isSaving}
            options={transformData(villes?.data || [])}
          />
        </Box>
        <CustomInput
          control={control}
          id="localisation"
          label="Localisation"
          name="localisation"
          defaultValue={immo?.localisation || ""}
          disabled={isSaving}
        />
      </Box>

      <div>
        {propertyArray.reduce((acc: any, property, index) => {
          if (index % 2 === 0) {
            const nextProperty = propertyArray[index + 1];

            acc.push(
              <>
                <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                  <Box sx={{ width: "100%" }}>
                    <SelectFree
                      fullWidth
                      control={control}
                      rules={{
                        required: "This field is required",
                      }}
                      id={property.title}
                      label={property.title}
                      name={property.title}
                      disabled={isSaving}
                      defaultValue={property.value}
                      options={["Oui", "Non"]}
                    />
                  </Box>

                  <Box sx={{ width: "100%" }}>
                    <SelectFree
                      fullWidth
                      control={control}
                      rules={{
                        required: "This field is required",
                      }}
                      id={nextProperty.title}
                      label={nextProperty.title}
                      name={nextProperty.title}
                      disabled={isSaving}
                      defaultValue={nextProperty.value}
                      options={["Oui", "Non"]}
                    />
                  </Box>
                </Box>
              </>
            );
          }
          return acc;
        }, [])}
      </div>

      <CustomInput
        control={control}
        id="description"
        label="Description du véhicule"
        name="description"
        disabled={isSaving}
        defaultValue={immo?.description || ""}
      />
      <input type="file" {...register("file")} onChange={uploadimg} />
    </Dialog>
  );
};

export default FormCreateEditImmo;
