import { useQueryClient } from "@tanstack/react-query";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import fetchApi from "../api/fetchApi";
import Dialog from "../components/dialog/Dialog";
import useAuth from "../hooks/useAuth";

type FormUplpoadType = {
  context: "car" | "immo";
  immo?: any;
  car: any;
  show: boolean;
  setShow: any;
  imageLink: string | null;
};

const FormUploadImage = ({
  context,
  car,
  immo,
  show,
  setShow,
  imageLink,
}: FormUplpoadType) => {
  const { handleSubmit, reset, register } = useForm();
  const [isSaving, setisSaving] = useState(false);
  const { token } = useAuth();
  const [image, setImage] = useState<any>();
  const queryClient = useQueryClient();
  //const isCar = context === "car";

  const updateCar = async (data: any) => {
    setisSaving(true);
    const formData = new FormData();
    formData.append("id", Number(car?.id).toString());
    formData.append("nom", car?.nom);
    formData.append("prix", Number(car?.prix).toString());
    formData.append(imageLink as string, image);

    fetchApi(`${context === "immo" ? "immo" : "car"}/update`, token)
      .upload(formData)
      .then((e) => {
        if (e.error) {
          toast(e.messages.message);
        } else if (e.title === "TypeError") {
          toast(e.message);
        } else {
          if (context === "car") {
            queryClient.invalidateQueries({
              queryKey: ["CarDetails"],
            });
            queryClient.invalidateQueries({
              queryKey: ["Automobile"],
            });
          }

          if (context === "immo") {
            queryClient.invalidateQueries({
              queryKey: ["Immobilier"],
            });
            queryClient.invalidateQueries({
              queryKey: ["ImmoDetails"],
            });
          }

          handleCloseEdit();
          reset();
          toast("Les  détails du véhicule ont été modifié", {
            type: "success",
          });
        }
      })
      .catch((err) => toast(err.message, { type: "error" }))
      .finally(() => setisSaving(false));
  };

  const uploadimg = (e: any) => {
    const file = e.target.files[0];
    if (file) {
      console.log(file);
      setImage(e.target.files[0]);
    }
  };

  const handleCloseEdit = () => {
    setShow(false);
  };

  return (
    <Dialog
      title={`Modifier l'image ${car?.nom}`}
      handleClose={handleCloseEdit}
      open={show}
      confirm={handleSubmit(updateCar)}
      isStoreActivated={false}
      isConfirming={isSaving}
      id={1}
    >
      <input type="file" {...register("file")} onChange={uploadimg} />
    </Dialog>
  );
};

export default FormUploadImage;
