const selectObjectByProperties = (data: any[], properties: string[]): any[] => {
  return data.map((item) => {
    const selectedData: any = {};
    properties.forEach((prop) => {
      if (item[prop] !== undefined) {
        selectedData[prop] = item[prop];
      }
    });
    return selectedData;
  });
};

export default selectObjectByProperties;

export const newSelectObjectByProperties = (
  data: any[],
  columns: { label: string; property: string }[]
): any[] => {
  return data.map((item) => {
    const selectedData: any = {};
    columns.forEach((prop) => {
      if (item[prop.property] !== undefined) {
        selectedData[prop.property] = item[prop.property];
      }
    });
    return selectedData;
  });
};
