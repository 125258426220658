import React from "react";
//import { AuthProvider } from "../providers/AuthProvider";
import Routes from "../routes";
import { AuthProvider } from "../providers/authProvider";

function Navigation() {
  return (
    <AuthProvider>
      <Routes />
    </AuthProvider>
  );
}

export default Navigation;
