import {
  AppsOutlined,
  LocalMallOutlined,
  LogoutOutlined,
  SettingsOutlined,
  StorefrontOutlined,
  TrendingUpOutlined,
} from "@mui/icons-material";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import { styled } from "@mui/material/styles";
import { useLocation } from "react-router-dom";
import MenuItem from "./menuItem";

export const drawerWidth = 280;

const navigation = [
  {
    name: "Tableau de Bord",
    href: "/",
    icon: AppsOutlined,
  },
  {
    name: "Automobiles",
    href: "/cars",
    icon: LocalMallOutlined,
  },
  {
    name: "Immobiliers",
    href: "/immobiliers",
    icon: TrendingUpOutlined,
  },
  {
    name: "Bonnes Affaires",
    href: "/deals",
    icon: StorefrontOutlined,
  },

  {
    name: "Parramètres",
    href: "/settings",
    icon: SettingsOutlined,
  },
];

const logout = {
  name: "Se Déconnecter",
  href: "/logout",
  icon: LogoutOutlined,
};

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

interface SideNavProps {
  open: boolean;
  handleDrawerOpenClose: () => void;
}

export default function SideNav({ open, handleDrawerOpenClose }: SideNavProps) {
  const location = useLocation();

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <Drawer
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          "& .MuiDrawer-paper": {
            width: drawerWidth,
            boxSizing: "border-box",
          },
          display: "flex",
          flexDirection: "column",
        }}
        variant="persistent"
        anchor="left"
        open={open}
      >
        <>
          <DrawerHeader sx={{ paddingY: 6, alignSelf: "center" }}>
            {<img src="/assets/logo_hc.jpg" alt="Logo" width={150} />}
          </DrawerHeader>
          <Divider />
          <List>
            {navigation.map((menuItem, key) => (
              <MenuItem
                menu={menuItem}
                key={key}
                isActive={location.pathname === menuItem.href}
              />
            ))}
          </List>
        </>

        <Divider sx={{ paddingTop: 15 }} />
        <MenuItem menu={logout} />
      </Drawer>
    </Box>
  );
}
