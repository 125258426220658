import Loading from "../../components/layout/loading/Loading";
import useAuth from "../../hooks/useAuth";

const Logout = () => {
  const { logout } = useAuth();

  logout();

  return <Loading isLoading />;
};

export default Logout;
